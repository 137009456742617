.profileModal {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
}

.profileModal .modal-content {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.185);
    /* border-radius: 5px !important; */
    max-width: 350px;
    float: right;
    padding: 0;
}

.profileModal .modal-body {
    padding: 0 !important;
}

.notificationModal {
    position: absolute;
    top: 2rem;
    right: 5.5rem;
    color: #0E2C4B;
}

.notificationModal .modal-content {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.089);
    /* border-radius: 5px !important; */
    width: 500px;
    float: right;
    padding: 0;
}

.notificationModal .modal-body {
    padding: 0 !important;
}

.modal-notification-header {
    padding: 0.5rem;
    font-size: 20px;
    font-weight: 600;
}

.modal,
.modal.show .modal-dialog {
    display: flex !important;
    margin: auto !important;
}

.modal-content {
    /* border-radius: 18px !important; */
    opacity: 1;
    padding: 20px;
}

.modal-header {
    border-bottom: none !important;
    min-height: 50px;
}

.modal-header span.close.pull-right {
    position: absolute;
    right: 20px;
    font-size: 40px;
    top: 2px;
    cursor: pointer;
    display: flex;
}

.disable-backdrop {
    background-color: rgba(0, 0, 0, 0);
}

.file-info .modal-content {
    background-color: #EDF1F7;
    padding: 0;
    /* border-radius: 18px; */
    border: 0;
    height: calc(100vh - 2rem);
}

.file-info .modal-body {
    padding: 0;
    overflow: hidden;
}

.file-info .modal-lg {
    max-width: calc(100% - 32px);
    width: 100%;
}

.file-info .file-preview {
    overflow: auto;
}

.file-info-detail {
    max-width: 396px;
    width: 100%;
    border-radius: 0 1rem 1rem 0;
    height: 100%;
    overflow: auto;
}



.file-info-detail .btn {
    font-weight: 600;
    display: inline-flex;
}

.file-info-detail .btn svg {
    margin-right: 4px;
}

.file-info-detail .btn.btn-outline-primary:hover path {
    fill: white;
}

.file-info-detail .border-light {
    border-color: #DADCE0 !important;
}

.doc-info {
    color: #8795A5;
    font-size: 1rem;
    font-weight: 600;
}

.file-title {
    color: #2B2B2B;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* width */
.file-info-detail::-webkit-scrollbar,
.file-preview::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.file-info-detail::-webkit-scrollbar-track,
.file-preview::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
.file-info-detail::-webkit-scrollbar-thumb,
.file-preview::-webkit-scrollbar-thumb {
    background: #9DA9B5;
    border-radius: 10px;
}

/* Handle on hover */
.file-info-detail::-webkit-scrollbar-thumb:hover,
.file-preview::-webkit-scrollbar-thumb:hover {
    background: #727d88;
}

@media (min-width:967px) {
    .file-info .modal-lg {
        max-width: calc(100% - 200px);
        width: 100%;
    }
}