.xadm-input-label {
  font-size: 0.8rem;
  font-weight: 900;
}
.xadm-input-text {
  font-size: 0.9rem;
}
.xadm-input-text:focus {
  border-color:#c76c30;
  box-shadow: none;
}
