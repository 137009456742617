.xadm-dashboard-sub-nav{
    height: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    text-align: left;
    width: 12rem;
    transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
}
.xadm-dash-logo{
    height: 2rem;
}
.xadm-nav-selected{
    border-right: 5px solid #2D4C41;
}