.xadm-login-bg {
  background: #2d4c41;
  /* height: 100vh; */
  padding: 20px 0px;
  position: fixed;
  bottom: 0;
 
}
.xadm-login{
    overflow-y: hidden;
}

.xadm-login-header {
  padding-top: 2rem;
}
.xadm-login-img{
    width: 100%;
    height:90vh;
    background-image: url("../../assets/icons/logo.png");
 background-size: contain;
 background-repeat: no-repeat ;
 background-position: center;
}

.xadm-login .xadm-card{
    width: 500px;
    position: absolute;
    right: 190px;
    border: 1px solid #2d4c41 !important;
}
