.xadm-card{
    border-radius: 1rem !important;
    padding: 1rem !important;
    border:none !important;
}
.xadm-card .card-header{
    background-color: white;
    border:none;
    font-weight: bold;
    padding: 0.5rem;
}

